@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .w-dscreen {
    @apply w-[100dvw];
  }

  .h-dscreen {
    @apply h-[100dvh];
  }

  dscreen {
    @apply w-dscreen h-dscreen;
  }

  .background-basic {
    @apply bg-background dark:bg-dark-background;
  }
  .background-translucent {
    @apply background-basic bg-opacity-70;
  }
  .bg-card {
    @apply bg-white dark:bg-neutral-800;
  }

  /* Straight and inverted color per light/dark modes */
  .text-basic,
  .text-inverted {
    @apply font-sans;
  }
  .text-basic {
    @apply text-foreground dark:text-dark-foreground;
  }
  .text-inverted {
    @apply text-dark-foreground dark:text-foreground;
  }

  /* Common body text styles */
  .text-body,
  .text-body-inverted {
    @apply text-base sm:text-md;
  }
  .text-body {
    @apply text-basic;
  }
  .text-body-inverted {
    @apply text-inverted;
  }

  .link {
    @apply cursor-pointer text-primary-500 dark:text-primary-200;
  }

  /* Common borders makeup per figma */
  .border-card-dark {
    border-color: #1e293b6c;
  }
  .border-card-light {
    border-color: #fdfdfd47;
  }
  .border-card {
    @apply border-card-dark dark:border-card-light;
  }
  .border-slate-light {
    @apply border-slate-50 border-opacity-10;
  }
  .border-slate-dark {
    @apply border-slate-600 border-opacity-30;
  }
  .border-fg {
    @apply border-foreground dark:border-dark-foreground/[.5] disabled:border-zinc-300 dark:disabled:border-zinc-700;
  }

  .border-standard {
    @apply border-foreground/[.4] dark:border-dark-foreground/[.3];
  }

  .border-standard-light {
    @apply border-foreground/[.2] dark:border-dark-foreground/[.15];
  }

  .border-standard-xlight {
    @apply border-foreground/[.1] dark:border-dark-foreground/[.12];
  }

  /* status based borders. primarily for input fields */
  .border-success {
    border-color: rgb(var(--success-color) / var(--tw-border-opacity));
  }
  .border-error {
    border-color: rgb(var(--error-color) / var(--tw-border-opacity));
  }
  .border-warning {
    border-color: rgb(var(--warning-color) / var(--tw-border-opacity));
  }
}

@layer components {
  .heading-sm {
    @apply font-bold !leading-[16px] !text-[12px] tracking-[-3%];
  }

  .heading-base {
    @apply font-bold !leading-[20px] !text-[14px] tracking-[-3%];
  }

  .heading-md {
    @apply font-bold !leading-[20px] !text-[16px] tracking-[-3%];
  }

  .heading-lg {
    @apply font-bold !leading-[24px] !text-[20px] tracking-[-3%];
  }

  .heading-xl {
    @apply font-bold !leading-[32px] !text-[28px] tracking-[-4%];
  }

  .heading-2xl {
    @apply font-bold !leading-[40px] !text-[32px] tracking-[-4%];
  }

  .heading-3xl {
    @apply font-bold !leading-[48px] !text-[40px] tracking-[-4%];
  }

  input,
  textarea {
    @apply !leading-[20px] !text-[14px] text-basic;
  }

  .button {
    @apply transition-all duration-300 ease-out cursor-pointer drop-shadow hover:drop-shadow-md active:scale-[98%];
    @apply font-sans font-bold border-2 rounded h-fit;
    @apply disabled:cursor-not-allowed disabled:active:scale-100;
  }

  .bg-button-tertiary {
    @apply bg-opacity-20 bg-primary-25 hover:bg-primary-50 hover:bg-opacity-40;
  }

  .button[data-loading='true'] {
    @apply cursor-wait pointer-events-none;
  }

  .button[data-size='small'] {
    @apply px-2.5 py-1.5 text-sm;
  }
  .button[data-size='base'] {
    @apply px-6 py-2.5 text-base;
  }
  .button[data-size='large'] {
    @apply px-6 py-3.5 text-lg;
  }

  .button[data-kind='primary'] {
    @apply text-white bg-primary-500 border-slate-light hover:bg-primary-600;
    @apply disabled:bg-neutral-400 disabled:text-white disabled:border-transparent;
    @apply dark:disabled:bg-neutral-700 dark:disabled:text-neutral-300;
  }
  .button[data-kind='secondary'] {
    @apply background-basic text-primary-500 border-primary-500 hover:text-primary-600;
    @apply hover:border-primary-600 dark:text-primary-300 dark:border-primary-300;
    @apply disabled:bg-transparent disabled:text-neutral-300 disabled:border-neutral-300;
  }
  .button[data-kind='tertiary'] {
    @apply border-transparent bg-button-tertiary text-primary-500 drop-shadow-md;
    @apply hover:drop-shadow-lg hover:text-primary-600 dark:text-primary-300;
    @apply disabled:bg-neutral-100 disabled:text-neutral-300 disabled:border-transparent;
    @apply disabled:dark:bg-neutral-600 disabled:dark:text-neutral-800;
  }
  .button[data-kind='floating'] {
    @apply shadow-sm drop-shadow-none dark:shadow-dark-foreground/[.05] dark:hover:shadow-dark-foreground/[.12] text-neutral-800 bg-card dark:text-zinc-100;
    @apply hover:text-neutral-950 dark:hover:text-zinc-50;
    @apply disabled:bg-neutral-100 disabled:text-neutral-300 disabled:border-transparent;
    @apply dark:disabled:bg-neutral-600 dark:disabled:text-neutral-300;
    @apply border border-standard-xlight;
  }

  /* input common styles */
  .disabled-input {
    @apply !cursor-not-allowed !bg-slate-600/[.15] dark:!bg-dark-background !text-zinc-500 placeholder:!text-zinc-400 dark:placeholder:!text-zinc-600 !border-foreground/[.12] !ring-transparent;
  }

  .input-common {
    @apply w-full !rounded-md shadow-sm ring-1 ring-transparent disabled:cursor-not-allowed;
    @apply ui-disabled:cursor-not-allowed;
  }
  .input-transitions {
    @apply transition-all duration-150 ease-in-out;
  }
  .input-bg {
    @apply bg-card disabled:bg-slate-600/[.15] dark:disabled:bg-dark-background;
    @apply ui-disabled:bg-slate-400/[.12];
  }
  .input-text {
    @apply lg:!py-2 disabled:text-zinc-500 disabled:placeholder:text-zinc-400;
    @apply placeholder:text-zinc-500 dark:placeholder:text-zinc-400;
    @apply dark:disabled:placeholder:text-zinc-600;
    @apply ui-disabled:text-slate-400;
  }
  .input-border {
    @apply !border !border-solid outline-none border-standard-light disabled:border-fg;
    @apply ui-disabled:ring-transparent ui-disabled:border-foreground/[.12];
  }

  .input-styles {
    @apply input-common input-transitions input-bg input-text input-border;
  }

  .input-menu-item {
    @apply flex items-center justify-between p-2 space-x-2 text-base text-basic;
    @apply transition-colors duration-150 ease-in-out cursor-pointer;
    @apply ui-active:text-white ui-active:bg-primary-400 ui-selected:font-bold;
    @apply ui-disabled:text-slate-400 ui-disabled:!cursor-not-allowed;
  }

  .input-menu-item-icon {
    @apply hidden w-4 h-4 transition duration-150 ease-out stroke-2;
    @apply stroke-primary-600 ui-selected:block ui-active:stroke-white;
  }

  .state-primary {
    @apply focus:ring-primary-600 focus:border-primary-600 focus:bg-primary-600/[.08];
    @apply dark:focus:ring-primary-700 dark:focus:bg-primary-700/[.08];
    /* @apply ui-open:ring-primary-600 ui-open:border-primary-600 ui-open:bg-primary-600/[.08];
    @apply dark:ui-open:ring-primary-700 dark:ui-open:bg-primary-700/[.08]; */
  }
  .state-success {
    @apply border border-success dark:border-success;
    @apply focus:bg-success/[.06] focus:ring-success;
    @apply ui-open:bg-success/[.06] ui-open:ring-success;
  }
  .state-error {
    @apply border border-error dark:border-error;
    @apply focus:bg-error/[.06] focus:ring-error;
    @apply ui-open:bg-error/[.06] ui-open:ring-error;
  }
  .state-warning {
    @apply border border-warning dark:border-warning;
    @apply focus:bg-warning/[.06] focus:ring-warning;
    @apply ui-open:bg-warning/[.06] ui-open:ring-warning;
  }

  /* popovers, flyout menus, etc */
  .popover-shadow {
    @apply shadow-lg dark:shadow-neutral-800;
  }
  .input-menu-border {
    @apply input-bg border-zinc-300 dark:border-zinc-600 !border-opacity-80 dark:!border-opacity-80;
  }

  /* animations */
  .shimmer {
    @apply relative overflow-clip after:absolute after:inset-0 after:w-full after:h-full;
    @apply after:bg-gradient-shimmer after:content-[''] after:animate-shimmer;
  }

  .see-more > * {
    display: inline;
  }
  .see-more > *::after {
    content: '\A';
    white-space: pre;
  }
  .see-more ul li {
    @apply py-1;
  }
}

:root {
  box-sizing: border-box;

  /* Light theme variables */
  --foreground-rgb: 28 28 28;
  --background-start-rgb: 249 249 249;
  --background-end-rgb: 249 249 249;

  --success-color: 16 185 129;
  --error-color: 225 29 72;
  --warning-color: 245 158 11;

  --primary-color-25: 207 142 255;
  --primary-color-50: 205 147 255;
  --primary-color-100: 196 121 255;
  --primary-color-200: 187 101 255;
  --primary-color-300: 162 57 255;
  --primary-color-400: 162 58 255;
  --primary-color-500: 152 34 255;
  --primary-color-600: 120 43 189;
  --primary-color-700: 94 34 148;
  --primary-color-800: 75 27 118;
  --primary-color-900: 49 16 79;

  /* Dark theme variables */
  --dark-foreground-rgb: 252 252 252;
  --dark-background-start-rgb: 28 28 28;
  --dark-background-end-rgb: 28 28 28;
}

* {
  @apply ring-offset-white dark:ring-offset-stone-800;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

/* Hide Calendar Icon In Chrome */
/* input[type='date']::-webkit-inner-spin-button, */
/* input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */
input[type='date'].input-styles {
  @apply min-h-[32px] lg:min-h-[38px];
}
/* input[type='date']::-webkit-datetime-edit {
  height: 18px;
} */

/* Some venues are negatively affecting this input with blanket css */
.calendar-header input[type='number'] {
  @apply bg-neutral-800 disabled:!bg-slate-600/[.15] dark:disabled:!bg-dark-background;
  @apply border-dark-foreground/[.15];
  @apply focus:!ring-primary-600 focus:!border-primary-600 focus:!bg-primary-600/[.08];
  @apply dark:focus:!ring-primary-700 dark:focus:!bg-primary-700/[.08];
}

table.calendar {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 8px;
  margin-bottom: 0px;
  @apply !border-none;
}

table.calendar td {
  @apply aspect-square !px-0 !py-0 !border-none !rounded-none;
}

table.calendar th {
  @apply !px-0 !py-2 text-white bg-stone-800 dark:bg-stone-700 !text-center !text-sm !border-none;
}

table.calendar tbody tr:nth-child(even) td {
  @apply bg-stone-300 dark:bg-stone-600 bg-opacity-30;
}
table.calendar tbody tr:nth-child(odd) td {
  @apply bg-stone-800 dark:bg-stone-700 bg-opacity-30;
}

table.calendar.bordered tr td:first-child,
table.calendar.bordered tr th:first-child {
  @apply border-l-2 border-card;
}

table.calendar.bordered tr td:last-child,
table.calendar.bordered tr th:last-child {
  @apply border-r-2 border-card;
}

table.calendar.bordered tr:last-child td {
  @apply border-b-2 border-card;
}

table.calendar tr:last-of-type td:first-of-type {
  @apply rounded-bl-md;
}

table.calendar tr:last-of-type td:last-of-type {
  @apply rounded-br-md;
}

.popover table.calendar tbody tr td button {
  @apply focus:!ring-white dark:focus:!ring-white text-basic;
}

.listbox-button.input-styles {
  @apply !rounded-md !py-2;
}

div.dark {
  @apply text-dark-foreground;
}

.formatted-content p {
  @apply my-4;
}
.formatted-content ul {
  @apply list-disc;
}

.formatted-content ol {
  @apply list-decimal;
}

.formatted-content ol,
.formatted-content ul {
  @apply pl-4 space-y-0 list-inside sm:pl-8;
}

.formatted-content ul li,
.formatted-content ol li {
  @apply text-body list-item;
}

section.overflow-y-auto {
  scrollbar-width: 1px;
}

@media (max-width: 640px) {
  .parallax-background {
    background-attachment: initial;
  }
}

input[type='date'] {
  @apply h-[32px] sm:h-[38px];
}

.react-international-phone-input-container {
  @apply relative flex items-center justify-between !p-0 h-8 sm:h-[38px] space-x-1;
}

.react-international-phone-country-selector {
  @apply w-12 h-full z-[1];
}

.react-international-phone-country-selector-button {
  @apply w-full h-full !bg-transparent !border-none;
}

.react-international-phone-country-selector-button__button-content {
  @apply flex items-center justify-start h-full pl-1.5 space-x-1;
}

.react-international-phone-country-selector-button__dropdown-arrow {
  @apply hidden;
}

input.react-international-phone-input {
  @apply w-full h-full bg-transparent border-0 input-common input-bg state-primary;
  @apply focus:ring-primary-600 focus:border-primary-600 focus:bg-primary-600/[.08];
  @apply dark:focus:ring-primary-700 dark:focus:bg-primary-700/[.08];
  @apply outline-none !rounded-tl-none !rounded-bl-none !border-0 ring-2;
}

.state-success input.react-international-phone-input {
  @apply border border-success dark:border-success;
  @apply focus:bg-success/[.06] focus:ring-success;
}
.state-warning input.react-international-phone-input {
  @apply border border-warning dark:border-warning;
  @apply focus:bg-warning/[.06] focus:ring-warning;
}
.state-error input.react-international-phone-input {
  @apply border border-error dark:border-error;
  @apply focus:bg-error/[.06] focus:ring-error;
}

.react-international-phone-country-selector-dropdown {
  @apply border input-menu-border outline-none absolute top-[32px] sm:top-[36px] left-0 max-h-[256px] overflow-y-auto ring-primary-600;
  @apply ml-0 z-[1] popover popover-shadow p-3 text-sm rounded text-slate-200;
  @apply border-opacity-20 dark:border-opacity-20 bg-stone-100 dark:bg-stone-800;
}

.react-international-phone-country-selector-dropdown__list-item {
  @apply flex items-center space-x-2 text-basic;
}

.react-international-phone-country-selector-dropdown__list-item-flag-emoji {
  @apply w-[32px];
}

div#headlessui-portal-root {
  z-index: 9999;
}

div.theme-context *, div[data-tl-widget] * {
  box-sizing: border-box;
}

button { 
  box-sizing: border-box;
}
